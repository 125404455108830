// To reference this file, add <\%= javascript_pack_tag 'kono_utils_bootstrap_view4' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// RICORDARSI di mantenere sincronizzate le dipendenze tra la versione non webpack negli assets,i moduli richiesti ecc

// jquery risulta essere ancora una dipendenza per bootstrap4.
global.jQuery = global.$ = require('jquery'); // rails-js cerca jquery in modo globale, quindi noi lo rendiamo globale in questo modo

global.Rails = require("@rails/ujs") // serve per data-confirm-modal, rendere Rails globale
global.Rails.start()
import * as ActiveStorage from "@rails/activestorage"
import Turbolinks from "turbolinks"
import "channels"
Turbolinks.start()
ActiveStorage.start()

// inizio dipendenza per interfaccia Bootstrap4
import "@fortawesome/fontawesome-free/css/all.css";
require('bootstrap');
require('popper.js');
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
require('data-confirm-modal');
global.KonoUtilsBootstrap4 = require("/bundle/ruby/2.7.0/gems/kono_utils_bootstrap_view4-0.3.2/app/assets/javascripts/kono_utils_bootstrap_view4/kono_utils_bootstrap_view4.js");
